/* .iconWrapper {
    margin-right: 20px;
} */
.hiwContainer {
    margin: 30px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.hiwInfo {
    min-height: 100px;
    max-height: 350px;
}
.hiwInfo p {
    padding-top: 10px;
}
.hiwInfo h3,
.hiwInfo p {
    /* padding: 0px; */
    margin: 0px;
}
.iconWrapper img {
    height: 120px;
    width: 120px;
}
.hiwInfo h3 {
    font-size: 22px;
}
.hiwInfo p {
    font-size: 16px;
}
.imageWrapper {
    height: 550px;
    overflow: hidden;
}
.imageWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
}
.contentWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.worksDis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 10px;
    row-gap: 10px;
}
@media (max-width: 1200px) {
    /* .hiwInfo h3 {
        font-size: 16px;
    } */
    .hiwInfo p {
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    .contentWrapper {
        width: 100%;
    }
    .worksDis {
        grid-template-columns: repeat(1, 1fr);
    }
}
