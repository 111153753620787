.main{
  font-weight: 900;
  color: black;
  font-size: 30px;
}

@media (max-width: 800px) {
  .main{
    font-size: 22px;
    text-align: left;

  }
 
}

