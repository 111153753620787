.cartHeader {
    padding: 10px 20px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
}
.cartHeader h1 {
    font-size: 24px;
}
.shoppingCartWrapper {
    position: fixed;
    width: 380px;
    height: 98vh;
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem #00000020 !important;
    top: 0;
    right: 10px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    bottom: 0;
    margin: auto;
    animation: slideFromRight 1s forwards; /* Animation duration is 1 second */
    animation-iteration-count: 1;
    z-index: 9999;
}
@keyframes slideFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateY(0%);
    }
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.cartItem {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 13px;
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
    position: relative;
}
.deleteItem {
    position: absolute;
    color: var(--main);
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    width: 20px;
}
.deleteItem img {
    width: 100%;
}
.deleteItem .cartItem div {
    display: flex;
    gap: 2px;
    flex-direction: column;
}
.cartBody {
    overflow-y: auto;
    height: 55%;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #00abd6 transparent;
}
.cartBody::-webkit-scrollbar-track {
    background: transparent; /* Change the color of the track */
}

/* Handle */
.cartBody::-webkit-scrollbar-thumb {
    background-color: #00abd6; /* Color of the scrollbar handle */
    border-radius: 10px; /* Rounded corners */
    border: 3px solid transparent; /* Border */
}

/* Handle on hover */
.cartBody::-webkit-scrollbar-thumb:hover {
    background-color: #0096c7; /* Darker color when hovering */
}
.imageWrapper {
    width: 90px !important;

    /* border: 1px solid var(--main); */
    border-radius: var(--radius-input);
}
.imageWrapper img {
    width: 100%;
    border-radius: var(--radius-input);
}
.shoppingCartWrapper span {
    color: #6c756d;
    font-weight: 400;
}
.shoppingCartWrapper p {
    font-size: 14px;
}
.cartFooter a {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 10px;
    height: fit-content;
}
.cartFooter {
    border-top: 1px solid #cfd4d0;
    padding-top: 10px;
    height: 37%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.cartPrice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: 20px;
}
.cartPrice p {
    color: #6c756d;
    width: 70%;
    font-size: 14px;
}
.cartPrice div {
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}
.cartPrice span {
    font-size: 18px;
    font-weight: 300;
}
.cartHeader img {
    height: 15px;
    cursor: pointer;
}
.shoppingCartWrapper label {
    outline: none;
    border-radius: var(--radius-input);
    border: 1px solid hsl(127, 4%, 44%, 0.5);
    width: 100px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.shoppingCartWrapper input {
    border: none;
    padding: 0px;
    width: 100%;
}
.shoppingCartWrapper label p {
    padding: 5px;
    border-right: 1px solid hsl(127, 4%, 44%, 0.5);
    margin: 0px;
    font-size: 14px;
    font-weight: 300;
}
.emptyCart {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 100%;
}
.emptyCart p {
    position: absolute;
    top: -50px;
    right: 0;
    left: 0;
    bottom: 0;
    color: var(--main);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
}
.emptyCart a {
    border: 1px solid var(--main);
    width: 100%;
    background-color: var(--main);
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s;
}
.emptyCart a:hover {
    background-color: white;
    color: var(--main);
}

.wood {
    border: 10px solid transparent;
    border-image: url('/public/images/general/wood.webp') 25;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.black {
    border: 10px solid #222;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.white {
    border: 10px solid hsl(0, 0%, 84%, 0.5);
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
@media (max-width: 800px) {
    .shoppingCartWrapper {
        width: 94%;
        left: 0;
        right: 0;
        z-index: 99999999999;
    }

    .wood {
        border: 5px solid transparent;
        border-image: url('/public/images/general/wood.webp') 25;
    }
    .black {
        border: 5px solid #222;
    }
    .white {
        border: 5px solid hsl(0, 0%, 84%, 0.5);
    }
}
