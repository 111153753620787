.guideWrapper {
    margin: 50px 0px;
}

.guideWrapper span {
    position: absolute;
    bottom: 0;
    left: 0px;
    color: white;
    padding: 10px 30px;
    border-radius: var(--radius-image);
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.guideCard div {
    height: 400px;
    overflow: hidden;
    position: relative;
}
.guideCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.good {
    background-color: rgb(36, 223, 161);
    text-transform: uppercase;
    font-size: 30px;
}

.bad {
    background-color: rgb(224, 48, 48);
    text-transform: uppercase;
    font-size: 30px;
}
.guide {
    padding: 20px 0px;
}
@media (max-width: 800px) {
    .guideWrapper {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}
