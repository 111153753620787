.checkoutWrapper {
    display: flex;
    /* background: linear-gradient(90deg, #fff 50%, var(--light-main) 50%); */
}
.progressBar {
    width: 300px;
    background-color: rgb(204, 204, 204);
    z-index: 999;
    display: flex;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    background-color: '#ccc';
}
.progressBar div {
    height: '20px';
    background-color: blue;
    color: white;
    padding-left: 2px;
}
.checkoutTerms {
    color: black;
}
form {
    padding: 60px 30px 0px 0px;
}
.lastSection {
    border-bottom: none !important;
}
.checkoutSummary {
    /* max-height: 100vh;
    min-height: 100vh; */
    /* padding: 60px 0px 0px 30px; */
    position: relative;
    height: 100%;
    background-color: var(--light-main);
}
.checkoutSummerProduct {
    border-bottom: 1px solid var(--border-color);

    padding-bottom: 20px;
    margin-bottom: 20px;
}
.checkoutSummerProduct > div {
    display: flex;
    width: 100%;
}
.flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.borderBottom {
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.checkoutSummerProduct > div > div:first-child {
    margin-right: 20px;
}
.checkoutSummerProduct img {
    width: 150px;
    border: 1px solid var(--border-color);
}
.checkoutSummerProduct a {
    font-size: 13px;
    color: var(--main);
}
.checkoutSummerProduct svg {
    margin-left: 5px;
}
.price {
    color: var(--main);
}
.totalPrice {
    font-size: 25px;
}

.inputError {
    border: 1px solid indianred;
    background-color: hsl(0, 100%, 50%, 0.1);
}
.checkoutWrapper form button {
    width: 100%;
    margin-top: 50px;
}
.discountText {
    font-size: 15px;
    padding-top: 10px;
    background-color: aquamarine;
    padding: 5px;
    border-radius: 5px;
}
.discount span {
    background-color: aquamarine;
    padding: 5px;
    border-radius: 5px;
}
.checkoutSummaryMobile > p {
    display: none;
}
.paymentMessageBody {
    width: 50%;
    padding: 20px;
    margin: 70px auto;
    background-color: white;
    padding: 10px 0px 30px 0px;
    box-shadow: 0 0.125rem 0.25rem #00000020 !important;
    margin-bottom: 10px;
    border-radius: var(--radius-image);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-color);
    align-items: center;
}
.paymentWrapper {
    padding: 50px 0px;
    min-height: 60vh;
}
.paymentLink {
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 20px;
    width: fit-content;
}
.paymentLink {
    display: flex;
    flex-direction: column;
}
.link {
    padding: 10px 30px;
    background-color: var(--main);
    color: var(--white);
    text-align: center;
    margin: 20px 0px;
}
.selectedDis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.selectedDis h3 {
    margin: 0px;
}
.selectedFrame {
    display: flex;
    align-items: center;
    gap: 5px;
}
.selectedFrame {
    color: #757575;
}
.cartItem {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    padding-bottom: 0px;
}
.cartItem img {
    width: 100px;
}
.flexPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wood {
    border: 10px solid transparent;
    border-image: url('/public/images/general/wood.webp') 25;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.black {
    border: 10px solid #222;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.white {
    border: 10px solid hsl(0, 0%, 84%, 0.5);
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.paymentDis {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-bottom: 20px;
}
.checkoutForm {
    padding-bottom: 20px;
}
.checkoutPrice {
    /* background-color: white; */
    /* border-top: 1px solid #6c756d; */
    padding-top: 10px;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}
.checkoutItem {
    min-height: fit-content;
    max-height: 500px;

    overflow-y: auto;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #00abd6 transparent;
}
.checkoutItem::-webkit-scrollbar-track {
    background: transparent; /* Change the color of the track */
}

/* Handle */
.checkoutItem::-webkit-scrollbar-thumb {
    background-color: #00abd6; /* Color of the scrollbar handle */
    border-radius: 10px; /* Rounded corners */
    border: 3px solid transparent; /* Border */
}

/* Handle on hover */
.checkoutItem::-webkit-scrollbar-thumb:hover {
    background-color: #0096c7; /* Darker color when hovering */
}
.sectionWrapper {
    height: 100%;
}
@media (max-width: 1200px) {
    .flex {
        flex-direction: column;
        align-items: baseline;
    }

    .checkoutSummaryMobile > p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--light-main);
        padding: 10px;
        border: 1px solid var(--main);
        border-radius: var(--radius-image);
        margin-top: 25px;
    }
    .checkoutSummerProduct img {
        border: 1px solid var(--main);
    }
    .checkoutWrapper {
        background: none;
    }
    form,
    .mobileSummary {
        padding: 0px;
        max-height: fit-content;
        min-height: fit-content;
    }
    .checkoutSummary {
        /* display: none; */
        border-radius: var(--radius-image);
        background-color: var(--light-main);
        border: 1px solid var(--main);
        display: none;
        padding: 20px;
    }
    .checkoutSummerProduct {
        transition: 0.5s;
        flex-direction: column;
    }
    .checkout {
        display: flex;
        flex-direction: column-reverse;
    }
    .selectedFrame {
        font-size: 12px;
    }
    .wood {
        border: 5px solid transparent;
        border-image: url('/public/images/general/wood.webp') 25;
    }
    .black {
        border: 5px solid #222;
    }
    .white {
        border: 5px solid hsl(0, 0%, 84%, 0.5);
    }
    .paymentDis {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
    .cartItem {
        padding: 0px;
        gap: 10px;
    }
    .checkoutPrice {
        padding: 0px;
    }
}
