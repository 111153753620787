/* PopUpGuide.module.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.popupOverlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border: unset !important;
}

.popupContent {
    position: relative;
    border: unset !important;
    width: 550px;
}
.popupContent img {
    width: 100%;
    border-radius: 8px;
}
.increase {
    width: 700px !important;
}
.closeButton {
    position: absolute;
    right: -45px;
    top: 0px;
    background-color: white;
    border-radius: 50px;
    color: var(--main);
    width: 40px !important;
    height: 40px !important;
    border: none;
    font-size: 24px;
    cursor: pointer;
}
.mobPop {
    display: none;
}

@media (max-width: 800px) {
    .mobPop {
        display: block;
    }
    .desPop {
        display: none;
    }
    .popupOverlay {
        width: 90%;
    }
    .mobileSlider {
        width: 75%;
    }
    .popupContent {
        width: 100%;
    }
    .popupContent img {
        width: 100%;
    }
    .closeButton {
        right: 0px;
        top: -45px;
    }
}
