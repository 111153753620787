.crumbs {
    display: flex;
    align-items: center;
    padding-top: 50px;
}

.crumbs a {
    position: relative;
    background-color: var(--white);
    color: var(--main);
}

.crumbs svg {
    width: 25px;
    color: var(--main);
}
