.loader {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.loaderLotti{
  width: 100%;
}
.loader > div {
  width: 30%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.loader p{
  position: absolute;
  bottom: 25%;
  left: 40%;
  width: fit-content;
  /* text-align: center; */
}
.loader h1{
  text-align: center;
  color: #cecece;
  font-size: 25px;
}

@media (max-width: 1100px) {
  .loader > div {
    width: 90%;
  }
  .loader p{
    bottom: 22%;
    left: 40%;
  }
}
@media (max-width: 600px) {
  .loader > div {
    width: 90%;
  }
  .loader p{
    bottom: 21%;
    left: 35%;
  }
}
