.shopBanner img {
    width: 100%;
    border-radius: 50px;
}
.shopBanner {
    position: relative;
}
.overlay {
    position: absolute;
    background-color: hsl(0, 0%, 0%, 0.5);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50px;
}
.bannerDes {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bannerDes h2 {
    font-size: 38px;
}
.bannerDes h2,
.bannerDes p {
    color: white;
    margin: 0px;
}
.bannerDes p {
    width: 400px;
    margin: 0px auto;
}
.shopDis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 10px;
    row-gap: 10px;
    padding-bottom: 100px;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    color: #000;
}
.headerPrice {
    display: flex;
    align-items: center;
}
.headerPrice span {
    text-decoration: line-through;
    text-decoration-color: red;
    padding-right: 10px;
}
.headerPrice p {
    font-size: 20px;
}
.cardImage {
    transition: 0.5s;
    box-shadow: 0 0.125rem 0.25rem #00000020 !important;
    margin-bottom: 10px;
    background-color: #f8f8f8;
    border-radius: var(--radius-image);
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.filterDis a {
    position: relative;
}
.cardWrapper span {
    font-size: 20px;
}
.cardImage img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.cardWrapper:hover .cardImage {
    transform: scale(1.05);
}
.filterTabs {
    display: flex;
    align-items: center;
    gap: 10px;
}
.filterTabs li {
    background-color: gray;
    padding: 2px 20px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 18px;
    transition: 0.3s;
    text-transform: uppercase;
}
.filterTabs li:hover {
    background-color: var(--main) !important;
    color: white !important;
}
.filterDis {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 30px;
    row-gap: 30px;
    padding-bottom: 100px;
}
.cardImage {
    transition: 0.5s;
    box-shadow: 0 0.125rem 0.25rem #00000020 !important;
    /* margin-bottom: 10px; */
    background-color: #f8f8f8;
    border-radius: var(--radius-image);
    width: 100%;
    height: 350px;
    position: relative;
    /* padding: 20px; */
}
.filterDis a:hover .cardImage {
    transform: scale(1.02);
}
.cardImage img {
    border-radius: var(--radius-image);
    display: block;
}
.cardContent {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    gap: 10px;
    justify-content: space-between;
}
.cardContent p {
    margin: 0px;
}
.cardBtn {
    font-size: 14px;
    background-color: var(--main);
    padding: 4px 20px;
    border-radius: 50px;
    color: white !important;
    transition: 0.3s;
    min-width: fit-content;
    max-width: 180px;
    text-align: center;
}
.black {
    border: 15px solid #222;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
    height: 240px !important;
    width: auto !important;
    border-radius: 0px !important;
}
.cardWrapper:hover .cardBtn {
    background-color: var(--dark-main);
}
.filterDropdown {
    background-color: #ebedec;
    border: none;
    border-radius: 50px;
    color: #000;
    cursor: pointer;

    height: 30px;
    padding: 0px 10px;
    width: 200px;
    margin-bottom: 20px;
}
.cardContent p {
    color: black;
}
.cardContent span {
    color: #676768;
}
.casePad .cardImage {
    padding-bottom: 0px !important;
}
.mugPad .cardImage {
    padding: 0px;
    height: fit-content;
}
.bestSeller {
    position: absolute;
    background-color: #4dd37c;
    color: white !important;
    padding: 2px 10px;
    top: 10px;
    left: 10px;
    border-radius: 10px 0px;
}
@media (max-width: 1400px) {
    .paintDis {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 800px) {
    .cardWrapper {
        padding: 0px;
    }
    .cardImage + div {
        display: flex;
        flex-direction: column-reverse;
        align-items: start;
        justify-content: flex-start;
        position: relative;
    }

    .cardImage p {
        font-size: 16px;
    }
    .shopDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .bannerDes p {
        width: 100%;
    }
    .shopBanner img,
    .overlay {
        border-radius: 20px;
    }
    .shopBanner img {
        height: 350px;
        object-fit: cover;
    }

    .filterDis {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
        padding-bottom: 20px;
    }
    .paintDis {
        grid-template-columns: repeat(1, 1fr);
    }
    .black {
        height: 150px !important;

        border: 10px solid #222;
    }
    .cardImage {
        height: fit-content;
        padding: 10px;
    }
    .bestSeller {
        font-size: 12px;
    }
}
