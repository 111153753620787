.orderWrapper {
    padding-bottom: 40px;
    /* background-color: var(--light-main); */
    border-radius: var(--radius-image);
    margin: 0px auto;
    min-height: 60vh;
}
.orderBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.orderFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--main);
}
.orderFooter p {
    padding: 5px 0px 0px 0px;
    margin: 0px;
}
.productInfoModel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0px;
    gap: 30px;
    width: 100%;
}

.productInfoModel img {
    width: 100%;
    cursor: pointer;
}

.imageWall {
    padding: 50px;
    background-color: #f8f8f8;
    border-radius: var(--radius-image);
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 550px;
    min-height: fit-content;
    transition: 0.5s;
    position: relative;
    overflow: hidden;
}
.imageMug {
    padding: 0px;
    width: 100%;
}
.imageWall img {
    width: 70%;
    height: 100%;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.imageTote img {
    width: 100%;
    box-shadow: unset;
}
.hoodieMain img {
    width: 100%;
    box-shadow: unset;
}
.imageMug img {
    width: 100%;
    box-shadow: unset;
    border-radius: var(--radius-image);
}
.wood {
    border: 20px solid transparent;
    border-image: url('/public/images/general/wood.webp') 25;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.black {
    border: 20px solid #222;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.white {
    border: 20px solid hsl(0, 0%, 84%, 0.5);
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.error {
    color: red;
    font-size: 12px;
}
form {
    padding: 0px;
}
.subTotalPrice {
    display: flex;
    flex-direction: column;
}
.subTotalPrice span {
    color: var(--main);
}
.subTotalPrice h1 {
    font-size: 24px;
}
.shopBtn {
    display: flex;
    align-items: center;
    gap: 10px;
}

.shopBtn button {
    width: 50%;
    text-align: center;
}
.shopColorBtn {
    border: 1px solid #4dd37c;
    color: white;
    background-color: #4dd37c;
    padding: 10px 30px;
    border-radius: var(--radius-input);
    cursor: pointer;
    font-size: 16px;
}
.shopColorBtn:hover {
    background-color: unset;
    color: #4dd37c;

    transition: 0.3s;
}
.borderError {
    border: 1px solid red;
    background-color: hsl(0, 100%, 50%, 0.1);
}
@media only screen and (max-width: 767px) {
    .productInfoModel {
        grid-template-columns: repeat(1, 1fr);
    }
    .vertical {
        height: unset !important;
    }

    .frameWall img {
        height: 100%;
    }
    .imageWall {
        padding: 20px;
        height: fit-content;
        width: 100%;
    }

    .wood {
        border: 15px solid transparent;
        border-image: url('/public/images/general/wood.webp') 25;
    }
    .black {
        border: 15px solid #222;
    }
    .white {
        border: 15px solid hsl(0, 0%, 84%, 0.5);
    }
    .shopBtn div {
        padding: 10px 20px;
    }
}

.active {
    border: 1px solid var(--main) !important;
}

.color {
    border: 1px solid #efefef;
    border-radius: var(--radius-image);
    height: 80px;
    cursor: pointer;
}
