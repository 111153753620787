.goToProduct {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    column-gap: 10px;
    row-gap: 20px;
    text-align: center;
    padding: 20px 0px;
}
.goToProduct p {
    cursor: pointer;
    border: 1px solid var(--main);
    background-color: var(--main);
    border-radius: 4px;
    padding: 7px 0px;
    font-size: 13px;
    color: white;
    transition: 0.3s;
}
.goToProduct p:hover {
    background-color: white;
    color: var(--main);
}
@media (max-width: 800px) {
    .goToProduct {
        grid-template-columns: repeat(2, 1fr);

        row-gap: 15px;
    }
    .goToProduct :last-child {
        grid-column: span 2; /* Last item spans two columns (full width) */
    }
    .goToProduct p {
        margin: 0px;
    }
}
