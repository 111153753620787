.banner {
    background-image: url('../../../public/images/general/ba.webp');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 450px;
    /* padding-top: 80px; */
    /* border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px; */
    background-color: #f2f1ed;
    display: flex;
}
.banner h1 {
    font-weight: 900;
    color: white;
    font-size: 40px;
}
.banner p {
    color: white;
}
.bannerText {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 50%;
    text-align: left;
    justify-content: center;
    justify-content: center;
}
.bannerImage img {
    width: 100%;
    display: block;
}
.bannerButton {
    width: 100%;
    display: flex;
}
.bannerButton a {
    width: 50%;
    margin-right: 5px;
    font-size: 14px;
    text-align: center;
}
.mobBanner {
    display: none;
}
@media (min-width: 1600px) {
    .banner {
        height: 650px;
        background-position: right;
    }
}
@media (max-width: 1200px) {
    .banner {
        display: flex;
        flex-direction: column;
        background-image: url('../../../public/images/general/bannerm.webp');
        height: 800px;
        background-position: bottom;
    }
    .mobBanner img {
        width: 100%;
    }
    .mobBanner {
        display: block;
    }
    .banner h1 {
        font-size: 36px;
    }
    .bannerButton a {
        font-size: 13px;
        width: fit-content;
    }
    .banner div:nth-child(1) {
        margin: 0 auto;
    }
    .bannerText {
        width: 100%;
        padding-top: 20px;
        text-align: center;
    }
    .banner p {
        padding-bottom: 10px;
    }
    .bannerButton {
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .banner h1 {
        text-align: center;
        font-size: 28px;
    }
    .banner {
        height: 520px;
    }
    .bannerButton {
        justify-content: center;
    }
    .bannerText {
        width: 100%;
        text-align: center;
        padding-top: 20px;
        justify-content: center;
    }
}
