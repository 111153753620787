.w100 {
    width: 100%;
    border: 10px solid #2f3133;
    border-radius: 0px;
}

.collectionBck {
    background: white;
    color: black;
    padding-bottom: 50px;
}
.collection-bck h1 {
    /* text-align: left; */
    padding: 50px 0px;
}
.more {
    text-align: center;
    color: var(--dark-main);
    padding-top: 20px;
    font-size: 18px;
    cursor: pointer;
    transition: 0.3s;
}
.more:hover {
    color: var(--main);
}
.mainHeader {
    text-align: center;
    padding: 20px 0px;
}
.collectionBanner {
    border-radius: 0px 0px 50px 50px;
    width: 100%;
}
.theme-display {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
.theme-box {
    border-radius: 0.5rem;
    background-color: #f8f8f8;
}
.theme-box img {
    border: 10px solid #2f3133;
    display: block;
    transition: 0.3s;
    box-shadow: 0px 0px 4px 4px #00000020 !important;
}
.theme-box img:hover {
    border: 10px solid #825f4b;
}
.collectionImage a {
    height: 400px;
}
.lineCollection a {
    height: 300px;
    width: 100%;
}
/* .lineCollection a:nth-child(1) {
    height: 500px;
} */
.lineCollection a {
    width: 40%;
}
.lineCollection a:nth-child(1) {
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
.lineCollection a:nth-child(1) img {
    width: 100%;
    /* margin: auto; */
}

.lineCollection {
    align-items: center;
    display: flex;
    gap: 10px;
}
.collectionImage img {
    height: 100%;
    object-fit: cover;
}
.mySwiper {
    display: none;
}
.mobSwiper {
    display: none;
}
@media (max-width: 800px) {
    .collectionImage {
        display: none;
    }
    .mySwiper {
        display: flex;
        align-items: center;
    }
    .more {
        display: none;
    }
    .mobSwiper {
        display: block;
    }
}
