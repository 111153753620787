.text {
    margin-right: 20px;
}

.form {
    /* align-items: center; */
    width: 45%;
    padding: 0px;
}
.form div {
    display: flex;
    width: 100%;
}
.newsLetter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9ebed;
    padding-bottom: 20px;
}
.input {
    /* margin-right: 10px; */
    border: 1px solid var(--main);
    padding: 15px;
    width: 75%;
}
.text {
    font-size: 24px;
    width: fit-content;
}
.button {
    margin-left: 10px;
    padding: 5px 30px;
    background-color: var(--main);
    color: white;
    border-radius: 8px;
    border: none;
    width: 25%;
    cursor: pointer;
}

.button:disabled {
    background-color: #cccccc;
}

.error {
    color: red;
    font-size: 12px;
    padding-top: 5px;
}
@media (max-width: 800px) {
    .newsLetter {
        flex-direction: column;
    }
    .text {
        padding-bottom: 20px;
    }
    .text,
    .form {
        text-align: center;
        width: 100%;
    }
    .input {
        width: 65%;
    }
    .button {
        width: 35%;
    }
}
