.homeCard img {
    border: 8px solid #2f3133;
    display: block;
    transition: 0.3s;
    width: 100%;
    transition: 0.3s;
    height: 340px;
    object-fit: cover;
}
.homeCard img:hover {
    border: 8px solid #6e4c41;
}
.homeInfoCard {
    display: flex;
    padding: 50px;
    flex-direction: column;
    height: 100%;
    width: 95%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    border-radius: var(--radius-image);
    background-color: #00abd6;
    color: white;
}

.homeInfoCard a {
    background-color: white;
    width: fit-content;
    padding: 10px 30px;
    border-radius: var(--radius-input);
    margin-top: 20px;
    color: var(--main);
}
.viewAll {
    width: 150px;
    margin: auto;
    display: flex;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 40px;
}
.viewAll a {
    background-color: var(--main);
    width: 100%;
    padding: 10px 30px;
    border-radius: var(--radius-input);
    margin-top: 20px;
    color: white;
    border: 1px solid transparent;
    transition: 0.3s;
}
.viewAll a:hover {
    border: 1px solid var(--main);
    background-color: white;
    color: var(--main);
}
.homePortraits {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 5px;
    row-gap: 5px;
}
.allProducts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
}

@media (max-width: 800px) {
    .homeInfoCard {
        height: 85vh;
        padding: 15px 20px;
        width: 100%;
    }
    .homePortraits {
        grid-template-columns: repeat(2, 1fr);
    }
    .homeCard img {
        height: 230px;
    }
    .parallax {
        display: none;
    }
}
