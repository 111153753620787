.cartBck {
    background: var(--main);
    padding: 50px 0px;
    text-align: center;
}
.cartBck h1 {
    color: white;
    font-weight: 800;
}
.cartBck p {
    color: white;
    padding-bottom: 50px;
}
.changeBck {
    background-color: #ff7628;
}
.greenBck {
    background-color: #258d8f;
}
@media (max-width: 1024px) {
    .cartBck h1 {
        text-align: center;
    }
}
@media (max-width: 768px) {
}
