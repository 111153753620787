.checkoutModal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 6;
}
.checkoutBody{
  background-color: var(--white);
  padding:50px;
  width: 550px;
  border-radius: var(--radius-image);
  margin: auto ;
  text-align: center;
  display: flex;
  position: relative;
  flex-direction: column;
}
.modalLottie{
  width: 250px;
  margin: 0px auto;
}
.checkoutBody h1{
  padding-top: 20px;
  font-size: 20px;
  margin: 0px;
}
.checkoutBody p{
  font-size: 16px;
  padding:0px 0px 10px 0px;
  margin: 0px;
}
.close{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--light-main);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 25px;
  cursor: pointer;
}
.modalSelect{
  display: flex;
  margin-top: 10px;
}
.modalSelect>div{
  width: 80%;
}
.modalSelect button{
  margin-left: 10px;
  background-color: var(--main);
  color: var(--white);
  outline: none;
  border: none;
  border-radius: var(--radius-input);
  width: 200px;
  cursor: pointer;
}
.message{
  font-size: 13px;
  font-style: italic;
}
.modalUpload button{
  background-color: var(--main);
  color: var(--white);
  outline: none;
  margin-top: 20px;
  padding:10px;
  border: none;
  border-radius: var(--radius-input);
  width: 100%;
  cursor: pointer;
}
.inputFile{
  display: flex;
  margin-bottom: 5px;
  width: 100%;
  
}
.inputFile input{
  width: 100%;
}
.file{
  position: relative;
  margin-top: 5px;
  width: 100%;
  border: 1px dashed var(--main);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: var(--main);
  cursor: pointer;
  font-size: 30px;
  padding: 10px;
}
.file svg{
  cursor: pointer;
}
.file input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
@media (max-width: 800px) {
  .checkoutBody{
    padding: 30px;
    width: 80%;
  }
  .checkoutBody h1{
    font-size: 16px;
  }
  .modalLottie{
    width: 150px;
  }
  .modalSelect button{
    width: 80px;
  }
}