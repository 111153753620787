.contact {
    background-color: hsl(192, 100%, 42%, 0.12);
    text-align: center;
}
.contact img {
    object-fit: cover;
    height: 500px;
    object-position: center;
}
.contactForm form {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0px;
}
.contactForm input {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #1a4e8a;
}
.contactForm label {
    padding: 10px 0px;
    text-align: left;
}
.contactForm {
    justify-content: space-between;
    display: flex;
    gap: 40px;
    padding-bottom: 50px;
}
.contactForm a {
    background-color: #00abd6;
    border: none;
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transform: translateZ(0);
    transition: all 0.2s ease-in-out;
    transition: 0.3s;
    border-radius: 8px;
}
.contactForm button {
    background-color: var(--main);
    border: 1px solid var(--main);
    padding: 10px;
    color: white;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.3s;
}
.contactForm button:hover {
    background-color: white;
    color: var(--main);
}
.contactForm textarea {
    border-radius: 8px;
    padding: 10px;
}
.contactFaq {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.contactFaq a:hover {
    background-position: right center;
    background-image: linear-gradient(to right, #3e0b62, #00abd6);
    background-size: 200% auto;
    transition: all 0.5s ease-in-out;
}
@media (max-width: 800px) {
    .contactForm {
        flex-direction: column;
    }
    .contact img {
        height: unset;
    }
}
