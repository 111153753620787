.petSelection {
    display: flex;
    gap: 10px;
}
.assetSingle {
    border: 1px solid white;
    transition: 0.5s;
    display: flex;
    border-radius: 20px;
    width: 100px;
}
.assetSingle img {
    /* height: 50px; */
}
.portraitType,
.portraitType img {
    display: flex;
    border-radius: 20px;
}

.active img {
    border: 1px solid;
    border-radius: 10px;
}

.image {
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    color: var(--main);
}
.image p {
    font-size: 14px;
}
.pickColor img {
    height: 70px;
}
.image input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
}
.singleImage {
    position: relative;
    margin-right: 10;
    border-radius: 20px;
}
.activeColor {
    border: 2px solid black !important;
}
.singleImage img {
    border-radius: 20px;
}
.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.color {
    height: 35px;
    width: 35px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #efefef;
}
.colorDis {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 20px 0px;
}
.petNoSelect {
    width: 40px;
    height: 40px;
}

.size {
    width: 60px;
    position: relative;
    height: 40px;
}
.size span {
    position: absolute;
    top: -10px;
    padding: 2px 10px;
    background-color: orangered;
    color: white;
    font-size: 10px;
    border-radius: 8px 0px;
    width: fit-content;
    left: -3px;
    right: 0;
    margin: auto;
}
.size,
.petNoSelect {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main);
    cursor: pointer;
    transition: 0.3s;
}
.size:hover,
.petNoSelect:hover {
    background-color: var(--main);
    color: white;
}
.size.active,
.petNoSelect.active {
    background-color: var(--main);
    color: white;
}
.assetOption {
    cursor: pointer;
}
.assetOption.active {
    color: var(--main);
}
.sizeGuide {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sizeGuide span,
.sizeGuide a {
    color: var(--main);
    cursor: pointer;
    font-size: 14px;
    transition: 0.3s;
}
.sizeGuide span:hover,
.sizeGuide a:hover {
    color: black;
}
.selectFrameDis {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 60%;
    column-gap: 10px;
    row-gap: 10px;
    text-align: center;
    position: relative;
}
.attributesHeading {
    padding-bottom: 10px;
}
.selectFrame {
    border: 1px solid var(--main);
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
}
.selectFrame.active {
    background-color: var(--main);
    color: white;
}
.frameDis img {
    border-radius: 8px;
    width: 100%;
    border: 2px solid transparent;
}
.frameDis div {
    position: relative;
}
.frameDis {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 70%;
    column-gap: 10px;
    row-gap: 10px;
}
.frameDis span {
    position: absolute;
    bottom: 10px;
    right: 5px;
    background-color: var(--main);
    padding: 2px 8px;
    font-size: 14px;
    border-radius: 6px 0px;
    color: white;
}
.glassDetails,
.canvasDetails {
    font-size: 11px;
    color: var(--main);
    cursor: pointer;
    transition: 0.3s;
    position: absolute;
    top: -20px;
    width: fit-content;
}
.glassDetails:hover,
.canvasDetails:hover {
    color: black;
}
.glassDetails {
    right: 53%;
}
.canvasDetails {
    right: 0px;
}
img.activeFrame {
    border: 2px solid var(--main);
}
.sizeWrap {
    flex-wrap: wrap;
}
.bestseller {
    background-color: #4dd37c;
    color: white;
    padding: 2px 10px;
    position: absolute;
    top: -10px;
    width: fit-content;
    margin: auto;
    left: 0;
    right: 0;
    font-size: 10px;
    border-radius: 8px 0px;
}
@media (max-width: 800px) {
    .frameDis span {
        padding: 2px 4px;
    }
    .frameDis {
        width: 100%;
    }
    .selectFrameDis {
        width: 100%;
    }
    .bestseller {
        font-size: 9px;
    }
    .image p {
        font-size: 12px;
    }
}
