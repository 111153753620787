.cardsReverse {
    flex-direction: row-reverse;
}
.cardsDisplay {
    align-items: center;
    display: flex;
}
.cardsDisplay:nth-last-child(1) {
    padding-bottom: 50px;
}
.cardsDisplay img {
    height: 500px;
    object-fit: cover;
    border-radius: 20px;
}
.cardsDisplay {
    padding-top: 50px;
    text-align: left;
    justify-content: space-between;
}

.cardsDisplay a {
    background-color: var(--main);
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 30px;
    border-radius: var(--radius-input);
    margin-top: 20px;
    border: 1px solid #21aed0;
    color: white;
    display: block;
    transition: 0.3s;
}
.cardsDisplay a:hover {
    border: 1px solid #21aed0;
    background-color: white;
    color: #21aed0;
}
@media (max-width: 800px) {
    .cardsDisplay {
        flex-direction: column;
        text-align: center;
    }
    .cardsDisplay p {
        padding-bottom: 10px;
    }
    .cardsDisplay h1 {
        text-align: center;
    }
    .cardsDisplay a {
        margin: 20px auto;
        /* margin: auto; */
    }
    .cardsDisplay img {
        height: unset;
    }
}
