.cardWrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* padding: 20px; */
    color: #000;
    position: relative;
}
.headerPrice {
    display: flex;
    align-items: center;
}
.headerPrice span {
    text-decoration: line-through;
    text-decoration-color: red;
    padding-right: 10px;
}
.headerPrice p {
    font-size: 20px;
}
.bestSeller {
    position: absolute;
    background-color: #4dd37c;
    color: white !important;
    padding: 2px 10px;
    top: 10px;
    left: 10px;
    border-radius: 10px 0px;
}
.trending {
    position: absolute;
    background-color: orangered;
    color: white;
    padding: 2px 10px;
    top: 10px;
    left: 10px;
    border-radius: 10px 0px;
}
.cardImage {
    transition: 0.5s;
    box-shadow: 0 0.125rem 0.25rem #00000020 !important;
    /* margin-bottom: 10px; */
    background-color: #f8f8f8;
    border-radius: var(--radius-image);
    width: 100%;
    height: 400px;
    /* padding: 20px; */
}
.cardImage img {
    border-radius: var(--radius-image);
    display: block;
}
.cardWrapper span {
    font-size: 20px;
}
.cardImage img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.cardWrapper:hover .cardImage {
    transform: scale(1.02);
}
.cardContent span {
    color: #676768;
    font-size: 14px;
}
.cardContent {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    gap: 10px;
    justify-content: space-between;
}
.cardContent p {
    margin: 0px;
    font-size: 16px;
}
.cardContent p:nth-child(3) {
    font-size: 18px;
}
.cardBtn {
    font-size: 14px;
    background-color: var(--main);
    padding: 4px 20px;
    border-radius: 50px;
    color: white;
    transition: 0.3s;
    min-width: fit-content;
    max-width: 180px;
    text-align: center;
}

.cardWrapper:hover .cardBtn {
    background-color: var(--dark-main);
}
@media (max-width: 800px) {
    .cardWrapper {
        padding: 0px;
    }
    .cardContent {
        display: flex;

        position: relative;
    }

    .cardImage + div p {
        font-size: 14px;
    }
    .cardWrapper:hover .cardImage {
        transform: unset;
    }
}
