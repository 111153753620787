.cart {
    border-radius: 10px;
    background: #fff;
    width: 1018px;
    margin: auto;
    margin-top: -50px;
    text-align: center;
    padding: 40px;
    text-align: left;
}
.cart p {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
}
.cart h1 {
    font-weight: 800;
}
.cart h3 {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
    color: #40474e;
}
.cart h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #40474e;
}
.cart ul {
    list-style: inside;
}
.cart li {
    padding-bottom: 10px !important;
}

@media (max-width: 1024px) {
    .cart {
        width: 90%;
    }
}
@media (max-width: 768px) {
    .cart {
        width: 90%;
        padding: 25px;
    }
}
