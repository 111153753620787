.header {
    position: sticky;
    top: 0;
    z-index: 5;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: white;
    padding: 10px 0px;
}
.logo {
    width: 200px;
}
.logo img {
    width: 100%;
}
.header ul {
    display: flex;
    align-items: center;
}
.header a {
    color: var(--dark-main);
    transition: 0.3s;
    font-weight: bold;
}
.header a:hover {
    color: var(--main);
}
.header ul li {
    padding: 0px 20px;
}
.mobileNav {
    display: none;
}
.mobileDisplay {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.mobileList {
    display: flex;
    align-items: center;
}
.mobileList li {
    list-style: none;
}
.mobileDisplay a {
    z-index: 3;
}
.hamburger-menu {
    width: 20%;
    z-index: 3;
}
.mobileDisplay a {
    color: white;
}
.mobileNav ul {
    text-align: left;
}
.mobileMenuContainer {
    background-color: white;
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: -120%;

    transition: all 0.2s ease-in-out;
    -webkit-animation: movedown 0.5s linear forwards;
    -moz-animation: movedown 0.5s linear forwards;
    -ms-animation: movedown 0.5s linear forwards;
    -o-animation: movedown 0.5s linear forwards;
    animation: movedown 0.5s linear forwards;
    color: black;
    z-index: 2;
}
.mobileMenuContainer.opened {
    top: 65px;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
}
.mobileMenuContainer ul {
    /* padding-top: 40px; */
    display: flex;
    flex-direction: column;
}
.mobileMenuContainer a {
    font-size: 18px;
    font-weight: 400;
    color: black;
    text-align: left;
    animation: slidein 0.5s linear forwards;
    transition: all 0.2s ease-in-out;
}
.mobileHeaderData {
    display: flex;
    align-items: center;
    font-size: 13px;
}
.mobileBtn {
    display: block;
    text-align: center !important;
}
.mobileMenuContainer li {
    padding-bottom: 20px !important;
}
.hamburgerMenu {
    background-color: #fff;

    border-radius: var(--radius-input);
    color: var(--main);
    margin-left: 5px;
    font-size: 20px;
    border-radius: var(--radius-input);
    outline: none;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.5s;
    z-index: 4;
}
.language {
    position: relative;
}
.languageList {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25px;
    cursor: pointer;
    background-color: var(--white);
    width: 100%;
    border-radius: var(--radius-input);
    display: none !important;
}
.languageListMob {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25px;
    cursor: pointer;
    background-color: var(--white);
    border-radius: var(--radius-input);
    display: none !important;
    left: -55%;
    z-index: 999;
}
.language:hover .languageList,
.language:hover .languageListMob {
    display: block !important;
}
.languageList li,
.languageListMob li {
    border-bottom: 1px solid #efefef;
    border-radius: var(--radius-input);
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px !important;
}
.languageActive {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.languageList li:hover,
.languageListMob li:hover {
    background-color: #efefef;
}
.languageList img,
.languageListMob img,
.languageActive img {
    width: 25px;
    margin-right: 5px;
}
.desktopHeader {
    justify-content: space-between;
}
.mobProducts {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    column-gap: 10px;
    row-gap: 40px;
    width: 100%;
    margin: auto;
    padding-top: 40px;
}
.mobProducts a {
    position: relative;
    flex-direction: column;
    align-items: center;
    display: flex;
    text-align: center;
}
.mobProducts img {
    width: 170px;
}
.mobProducts p {
    font-size: 14px;
    text-align: center;
}
.mobProducts span {
    position: absolute;
    font-size: 12px;
    background-color: #4dd37c;
    color: white !important;
    padding: 2px 10px;
    top: -20px;
    left: 0px;
    border-radius: 10px 0px;
}
.trending {
    background-color: orangered !important;
}
.newRib {
    background-color: #ffb6c1 !important    ;
}
.headerArr {
    width: 13px;
    rotate: 90deg;
    transition: transform 0.3s ease-in-out;
    filter: invert(100%);
}
.rotateArrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}
.allProducts p {
    color: var(--dark-main);
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 5px;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: -400px;
    display: none;
    background-color: white;
    padding-top: 40px;
    border-bottom: 1px solid #efefef;
    border-left: 1px solid #efefef;
    border-radius: 0 0 20px 20px;
    border-right: 1px solid #efefef;
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
    z-index: 1;
    border-radius: 12px;
    width: auto;
    padding: 40px;
}
.desBtn {
    position: relative;
    z-index: 99;
}
.allProducts:hover .dropdown {
    display: block;
}

.dropdownContent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 10px;
}

.dropdownContent img {
    width: 100%;
    height: auto;
}

.allProducts {
    position: relative;
}

.allProducts img {
    margin-left: 5px;
}
.dropdown {
    left: -250px;
}
@media (max-width: 1400px) {
    .header a,
    .allProducts p {
        font-size: 14px;
    }
    .header ul li {
        padding: 0px 10px;
    }
    .logo {
        width: 150px;
    }
    .allProducts p {
        gap: 5px;
    }
    .desktopHeader {
        width: 100% !important;
    }

    .mobProducts img {
        width: 120px;
    }
}
@media (max-width: 800px) {
    .mobileNav {
        display: block;
    }
    .desktopHeader {
        display: none;
    }
    .logo {
        width: 150px;
    }
    .mobileBtn,
    .mobileBtnList {
        width: 90%;
        text-align: center;
    }
    .mobileArrow li {
        width: 90%;
        margin: auto;
        padding: 0px !important;
        border-bottom: 1px solid #d7d1d1;
        /* border-top: 1px solid rgb(188, 182, 182); */
        padding: 10px 0px !important;
    }
    .mobileBtnList {
        border: unset !important;
    }
    .mobileArrow a {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
    }
    .mobileBtnList a {
        justify-content: center;
    }
    .mobileArrow img {
        width: 15px;
        filter: invert(100%);
    }
    .mobProducts img {
        width: 100%;
    }
    .mobProducts {
        width: 90%;
    }
    .mobProducts span {
        font-size: 9px;
    }
}
