.footer {
    color: white;
    background: #00010a;
    padding: 50px 0px;

    border-top: 1px solid rgb(128 128 128);
}

.footerSection {
    display: flex;
    flex-direction: column;
}
.paymentDisplay {
    display: flex;
    align-items: center;
}
.footerHeader {
    /* width: 80%; */
    font-size: 20px;
}
.paymentDisplay img {
    margin-right: 20px;
}
.paymentDisplay img:nth-child(2) {
    background-color: white;
    padding: 5px 13px;
    border-radius: 6px;
}
.footerSection h1 {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 20px;
}
.footerSection a {
    color: var(--white);
    padding-bottom: 10px;
    transition: 0.3s;
}
.footerSection a:hover {
    color: var(--main);
}
@media (max-width: 800px) {
    .footer h1 {
        font-size: 15px;
    }
}
